// extracted by mini-css-extract-plugin
export var authorButton = "style-module--author-button--95b30";
export var authorComics = "style-module--author-comics--f2b1c";
export var authorCover = "style-module--author-cover--8b431";
export var authorCoverWrapper = "style-module--author-cover-wrapper--15db0";
export var authorDetail = "style-module--author-detail--d8872";
export var authorInfo = "style-module--author-info--35c62";
export var authorJob = "style-module--author-job--46c4b";
export var authorLink = "style-module--author-link--48522";
export var authorSubtitle = "style-module--author-subtitle--fc8b3";
export var authorTech = "style-module--author-tech--f4498";
export var authorTechTitle = "style-module--author-tech-title--f3aee";
export var authorTitle = "style-module--author-title--09703";
export var authorTop = "style-module--author-top--06a8d";